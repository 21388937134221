module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"nasco","promptForAccessToken":true,"apiEndpoint":"https://nasco.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","lang":"el","name":"NasCo","short_name":"NasCo","description":"NasCo","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"./src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e550235d0d0cd8e15bbad2b03e1c24b4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-WZT969M6"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**"]}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
