
// manifest properties.
export default {
  siteUrl: "https://www.nasco.gr",
  siteLang: "el-gr",
  siteCurrency: "EUR",
  title: "NasCo",
  shortTitle: "NasCo",
  description: "NasCo",
  logoUrl: "https://www.nasco.gr/images/logo.png",

  // Values used in manifest
  siteBackgroundColor: "#fff",
  siteThemeColor: "#000",
  siteIcon: "./src/images/icon.png",

  // Image Details used as fallback at metadata
  defaultImage: "https://www.nasco.gr/images/logo.png",
  defaultImageWidth: "512",
  defaultImageHeight: "512",
  defaultImageAlt: "Nasco Logo",
};
