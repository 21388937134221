exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/About.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-collection-jsx": () => import("./../../../src/templates/Collection.jsx" /* webpackChunkName: "component---src-templates-collection-jsx" */),
  "component---src-templates-home-page-jsx": () => import("./../../../src/templates/HomePage.jsx" /* webpackChunkName: "component---src-templates-home-page-jsx" */),
  "component---src-templates-ideas-jsx": () => import("./../../../src/templates/Ideas.jsx" /* webpackChunkName: "component---src-templates-ideas-jsx" */),
  "component---src-templates-nasco-jobs-jsx": () => import("./../../../src/templates/NascoJobs.jsx" /* webpackChunkName: "component---src-templates-nasco-jobs-jsx" */),
  "component---src-templates-post-list-jsx": () => import("./../../../src/templates/PostList.jsx" /* webpackChunkName: "component---src-templates-post-list-jsx" */),
  "component---src-templates-post-page-jsx": () => import("./../../../src/templates/PostPage.jsx" /* webpackChunkName: "component---src-templates-post-page-jsx" */),
  "component---src-templates-product-page-jsx": () => import("./../../../src/templates/ProductPage.jsx" /* webpackChunkName: "component---src-templates-product-page-jsx" */),
  "component---src-templates-project-list-jsx": () => import("./../../../src/templates/ProjectList.jsx" /* webpackChunkName: "component---src-templates-project-list-jsx" */),
  "component---src-templates-project-page-jsx": () => import("./../../../src/templates/ProjectPage.jsx" /* webpackChunkName: "component---src-templates-project-page-jsx" */),
  "component---src-templates-service-page-jsx": () => import("./../../../src/templates/ServicePage.jsx" /* webpackChunkName: "component---src-templates-service-page-jsx" */),
  "component---src-templates-simple-page-jsx": () => import("./../../../src/templates/SimplePage.jsx" /* webpackChunkName: "component---src-templates-simple-page-jsx" */)
}

